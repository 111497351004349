import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IArtist } from 'src/models/artists.model';

// import { do } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArtistService {

  constructor(private http: HttpClient) { }

  // GetArtists(): Observable<IArtist[]> {
  //   return this.http.get<IArtist[]>('http://localhost:8080/api/getArtists');
  // }

  // GetArtistById(id: number): Observable<IArtist> {
  //   return this.http.get<IArtist>(`http://localhost:8080/api/getArtist/${id}`)
  //   .pipe(map(val => val[0]));
  // }

  GetArtists(): Observable<IArtist[]> {
    return this.http.get<IArtist[]>('/api/getArtists');
  }

  GetArtistById(id: number): Observable<IArtist> {
    return this.http.get<IArtist>(`api/getArtist/${id}`)
    .pipe(map(val => val[0]));
  }
}
