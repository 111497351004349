import { Component, OnInit } from '@angular/core';
import { ScrollEvent } from 'ngx-scroll-event';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  visible: boolean;
  scrolled: boolean = true;
  menuCheck: boolean;

  constructor() { }

  ngOnInit() {
    this.visible = false;
  }

  public handleScroll(event: ScrollEvent) {

    if (event.isReachingTop) {
      console.log('true');
      this.scrolled = true;
    }

    if (event.isReachingBottom) {
      console.log('false');
      this.scrolled = false;
    }
  }

  public onMenuChange(event: any) {
    this.menuCheck = !this.menuCheck;
  }
}
