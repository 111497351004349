import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ArtistService } from '../artist.service';

import {Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Art, IArtist } from 'src/models/artists.model';
import { validateConfig } from '@angular/router/src/config';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss']
})
export class ArtistComponent implements OnInit, OnDestroy {
  @Input() id: number;

  navSub: any;
  artistData: any[];
  public _album: any = [];
  albumArr: IAlbum[] = [];
  slicePoints: number[] = [];
  public arts$: Observable<Art[]>;
  public artist$: Observable<IArtist>;
  public slicePoints$: Observable<number[]>;

  constructor(private _lightbox: Lightbox,
    private artService: ArtistService,
    private route: ActivatedRoute,
    private router: Router) {
      this.navSub = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    }

  ngOnInit() {
    this.route.params.subscribe( params =>
      this.id = params['id']);
    this.artist$ = this.artService.GetArtistById(this.id);

    if (this.albumArr.length !== 0) {
      this.albumArr.length = 0;
    }

    this.arts$ = this.artist$
      .pipe(
        map(val =>  val.art),
        tap(arts => {
          arts.forEach(art => {
            this.albumArr.push({src: art.imageLoc, caption: art.artTitle + ' - ' + art.artDesc, thumb: null});
          });
        })
      );

      if (this.slicePoints.length !== 0) {
        this.slicePoints.length = 0;
      }

      this.slicePoints$ = this.artist$
        .pipe(
          map(val => val.slicePoints),
          tap(num => {
            num.forEach(sep => {
              this.slicePoints.push(sep);
            });
          })
        );

        this.slicePoints$.subscribe();
  }

  ngOnDestroy(): void {
    if (this.navSub) {
      this.navSub.unsubscribe();
    }

    if (this.albumArr) {
      this.albumArr.length = 0;
    }

    if (this.slicePoints) {
      this.slicePoints.length = 0;
    }
  }

  openLightbox(index: number): void {
    this._lightbox.open(this.albumArr, index);
  }

  closeLightbox(): void {
    this._lightbox.close();
  }

}
